import process from 'process';

export const config = {
  modeEnv: process.env.NEXT_PUBLIC_MODE_ENV,
  useWhiteListTokens: true,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  subgraphUrl: process.env.NEXT_PUBLIC_SUBGRAPH_URL,
  middlewareUrl: process.env.NEXT_PUBLIC_MIDDLEWARE_URL,
  explorerApiUrl: process.env.NEXT_PUBLIC_EXPLORER_API_URL,
  contracts: {
    factory: process.env.NEXT_PUBLIC_CORE_FACTORY,
    router: process.env.NEXT_PUBLIC_CORE_ROUTER,
    weth: process.env.NEXT_PUBLIC_WETH_CONTRACT,
    erc20: process.env.NEXT_PUBLIC_ERC20_CONTRACT
  },
  defaultTokens: {
    from: process.env.NEXT_PUBLIC_DEFAULT_FROM_TOKEN_ADDRESS ?? '',
    to: process.env.NEXT_PUBLIC_DEFAULT_TO_TOKEN_ADDRESS ?? ''
  },
  documentation: 'https://codex-finance.gitbook.io/codex-finance/',
  discord: 'https://discord.gg/VaWCzGCQK3',
  twitter: 'https://twitter.com/codexfi',
  telegram: 'https://t.me/CODEX_Dexchange'
};
